:root {
	/* Server Status Colors */
	--status-transparency: 70%;
	--status-online: #309747;
	--status-offline: #973030;
	--status-setup: #977430;

	/* Server Role Colors */
	--role-owner: #cb2500;
	--role-member: #00cbbf;
}

:root[data-theme='dark'] {
	--primary-color: #6596d0;
	--secondary-color: #578fd3;

	--text-color: #ededed;
	--text-color-faded: #ededed99;

	--background-color: #171717;
	--background-color-1: #1f1f21;
	--background-color-2: #27272a;
	--background-color-3: #323235;
	--background-color-4: #3d3d3f;

	--secondary-background-color: #8eb0da;
}

:root[data-theme='light'] {
	--primary-color: #6091c5;
	--secondary-color: #528ac8;

	--text-color: #383842;
	--text-color-faded: #38384299;

	--background-color: #b5b5b5;
	--background-color-1: #c5c5c5;
	--background-color-2: #d5d5d5;
	--background-color-3: #e5e5e5;
	--background-color-4: #f5f5f5;

	--secondary-background-color: #8eb0da;
}

html[data-theme='dark'] {
	color-scheme: dark;
}

html[data-theme='light'] {
	color-scheme: light;
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--background-color) !important;
	color: var(--text-color) !important;
}

.title {
	font-weight: 300;
	line-height: 0;
	color: var(--primary-color);
}

/* Overrides for next.js */

a {
	text-decoration: none;
	transition: filter 0.1s ease-in-out;
	color: var(--primary-color);
	filter: brightness(1.2);
}

a:hover {
	filter: brightness(1);
}

code {
	background-color: var(--background-color-1);
	color: var(--text-color);
	padding: 0 0.4em;
	border-radius: 0.2em;
}

[data-code] {
	user-select: none;
	cursor: pointer;
}

hr {
	border-block-start-style: none;
	border: 1px solid var(--background-color-4);
	border-bottom: none !important;
}
